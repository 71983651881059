import { render, staticRenderFns } from "./importMining.vue?vue&type=template&id=4710c775&scoped=true&"
import script from "./importMining.vue?vue&type=script&lang=js&"
export * from "./importMining.vue?vue&type=script&lang=js&"
import style0 from "./importMining.vue?vue&type=style&index=0&id=4710c775&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4710c775",
  null
  
)

export default component.exports